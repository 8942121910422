import React from 'react'
import { createPortal } from 'react-dom'

import { Background, ModalContent } from './style'

type ModalProps = {
  children: React.ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  locationToRender: Element | DocumentFragment;
  zIndexOverlay?: number;
};

export const Modal = ({
  children,
  isModalOpen,
  setIsModalOpen,
  locationToRender,
  zIndexOverlay,
}: ModalProps) => {
  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    isModalOpen && (
      <>
        {createPortal(
          <ModalContent>
            <Background onClick={closeModal} zIndexOverlay={zIndexOverlay} />
            {children}
          </ModalContent>,
        locationToRender,
      )}
      </>
    )
  )
}
