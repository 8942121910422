import styled from 'styled-components'

type backgroundProps = {
  zIndexOverlay?: number;
}

export const ModalContent = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  overflow-y: auto;


  display: flex;
  align-items: center;
  justify-content: center;

`

export const Background = styled.div`
    background-color: rgba(0, 0, 0, .4);
    height: 100%;
    opacity: 0.8;
    position: fixed;
    width: 100%;
    z-index: ${(props: backgroundProps) => props.zIndexOverlay ? props.zIndexOverlay : '-1' };
`
